//src/features/app/app-form.tsx
import { useEffect, useMemo, useState, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, FormikProps } from "formik";
import { useCustomizations, useError, ErrorCard, doFetchAsJson, IPayloadError } from "@personicom/customizations";
import { AppFormProps, AppActionProps } from "app-types";
import { createSchema, createInitialValues, getQueryStringValues, validateForm, compileValues, prepareBody, getSearchParam, DEFAULT_ACTION } from "./app-helpers";
import { FormGridContainer, FormActionsContainer, LoadingWrapper, RenderResult } from "./app-form-components";
import AppFormWatcher from "./app-form-watcher";
import AppWaitContainer from "./app-wait-container";
import SuccessCard from "../../components/success-card";


// Styles moved to a dedicated styles file (e.g., app-form-styles.ts) or left inline
const useStyles = makeStyles((theme: any) => ({
  title: { marginBottom: theme.spacing(2) },
  form: { width: "100%" },
  loading: { marginBottom: theme.spacing(2), marginTop: "-10%" },
  errorContainer: { "& .MuiGrid-container": { width: "100%" } },
}));

// Main AppForm component
const AppForm: React.FC<{ appConfig: AppFormProps }> = ({ appConfig }) => {
  const classes = useStyles();
  const { client, subdomain, blobUrl } = useCustomizations();
  const { error, setError, clearError } = useError();

  // State management
  const [isInitialized, setInitialized] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isWorking, setWorking] = useState(false);
  const [theAppUrl, setAppUrl] = useState<null | string>(null);
  const [schema, setSchema] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<null | Record<string, string>>(null);
  const [injectedValues, setInjectedValues] = useState<null | Record<string, any>>(null);
  const [unused, setUnused] = useState<Record<string, any>>({});
  const [wasQueued, setWasQueued] = useState<boolean>(false);
  const [videoInfo, setVideoInfo] = useState<any>(null);
  const [submitPath, setSubmitPath] = useState<string | null>(null);

  // Token management
  let token = useMemo(() => getSearchParam("token", true) ?? null, []);
  const apiKey = useMemo(() => getSearchParam("apiKey", true) ?? null, []);
  token = token ?? apiKey;

  // Form reference
  const formikRef = useRef<FormikProps<Record<string, string>> | null>(null);

  // Actions for the form
  const formActions = useMemo(() => (appConfig?.actions && appConfig.actions.length > 0) ? [appConfig.actions[0]] : [DEFAULT_ACTION], [appConfig]);

  // Form initialization
  useEffect(() => {
    async function loadForm() {
      const defaults = await getQueryStringValues();
      const s = createSchema(appConfig.inputs);
      const i = createInitialValues(appConfig.inputs, defaults, blobUrl);

      const formValidation = validateForm(appConfig, i);
      setValidationError(formValidation);

      setSchema(s);
      setInitialValues(i);
      setInjectedValues(defaults);

      // Handle unused values
      const uv = Object.keys(defaults).reduce((output: Record<string, any>, key: string) => {
        const inp = appConfig.inputs.find(i => i.fieldId === key);
        if (!inp) output[key] = defaults[key];
        return output;
      }, {});

      setUnused(uv);
      setInitialized(true);
    }
    loadForm();
  }, [appConfig, blobUrl]);

  // Handles form submission based on action
  const submitFormWithAction = (action: AppActionProps, formikProps: FormikProps<Record<string, string>>) => (): Promise<any> => {
    setSubmitPath(action.submitPath ?? null);
    return formikProps.submitForm();
  }

  // Form submission handler
  async function submitForm(values: any, actions: any) {
    const path = submitPath ?? "/videos";
    const toSend = compileValues(appConfig?.inputs || [], values, client, subdomain, unused, true);
    const body = prepareBody(toSend);

    setWorking(true);
    clearError(); // Clear any previous errors before submission

    try {
      const response = await doFetchAsJson(path, "POST", body, token);

      if (isPayloadError(response)) {
        handleError(response, actions);
      } else {
        handleSuccess(response, actions);
      }
    } catch (error: any) {
      handleUnexpectedError(error, actions);
    } finally {
      setWorking(false);
      setSubmitPath(null);
    }
  }

  // Helper function to check if the response is an IPayloadError
  function isPayloadError(response: any): response is IPayloadError {
    return response && response.isError;
  }

  // Handle errors from API fetch or payload.
  function handleError(error: IPayloadError, actions: any) {
    const errorDetails = typeof error.details === 'object' && 'errors' in error.details
      ? error.details.errors
      : error.details;

    setError({
      title: error.message,
      detail: errorDetails || "No additional error details available",
    });

    actions.setStatus({ isError: true, message: error.message });
  }

  // Handle successful form submission
  function handleSuccess(response: any, actions: any) {
    setWasQueued(true);
    setVideoInfo(response);
    if (!appConfig?.isPolling) actions.resetForm();
    actions.setStatus(null);
  }

  // Handle unexpected errors
  function handleUnexpectedError(error: any, actions: any) {
    setError({
      title: "Unexpected Error",
      detail: error?.message || "An unexpected error occurred during form submission.",
    });
    actions.setStatus({ isError: true, message: error?.message || "An unexpected error occurred." });
  }

  function resetForm() {
    setInitialValues({});
    setAppUrl(null);
    setWorking(false);
  }

  function onRestart() {
    setWasQueued(false);
    clearError();
    formikRef.current?.resetForm();
  }

  if (!isInitialized || !initialValues || !schema || !appConfig) {
    return <LoadingWrapper message="Initializing app page..." />;
  }

  return (
    <Grid id="form-container" container justify="center">

      {validationError && (
        <ErrorCard
          message="Form Initialization Error"
          details={validationError}
        />
      )}

      {error && (
        <ErrorCard
          message={error.title}
          details={error.detail}
          onBackAction={{
            label: "Back to Form",
            onClick: () => {
              clearError(); // Clear the error using the custom hook
            }
          }}
        />
      )}
      {appConfig.appTitle && (
        <Grid item container justify="center">
          <Typography variant="h6" color="primary" className={classes.title}>{appConfig.appTitle}</Typography>
        </Grid>
      )}
      {isWorking && (
        <Grid item container className={classes.loading}>
          <LoadingWrapper message="Working..." />
        </Grid>
      )}
      {theAppUrl && (
        <RenderResult url={theAppUrl} onReset={resetForm} />
      )}
      {wasQueued && appConfig.isPolling && (
        <AppWaitContainer videoId={videoInfo?.videoId} campaignId={videoInfo?.campaignId} videoUrl={videoInfo?.playerUrl} onRestart={onRestart} />
      )}
      {wasQueued && !appConfig.isPolling && (
        <SuccessCard message={appConfig.videoQueuedMessage || "Video queued successfully."} onRestart={onRestart} />
      )}
      {!isWorking && !theAppUrl && !wasQueued && (
        <Grid item container>
          <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={schema} onSubmit={submitForm}>
            {formikProps => (
              <Form className={classes.form}>
                <AppFormWatcher formikProps={formikProps} injectedValues={injectedValues} allInputs={appConfig.inputs} unusedValues={unused} />
                <Grid id="form-grid" container direction="column" wrap="nowrap">
                  <FormGridContainer
                    inputs={appConfig.inputs}
                    formikProps={formikProps}
                    injectedValues={injectedValues}
                    disabled={isWorking}
                    unused={unused}
                  />
                  <FormActionsContainer
                    actions={formActions}
                    onSubmitAction={(action) => submitFormWithAction(action, formikProps)}
                    isWorking={isWorking}
                    isValid={formikProps.isValid}
                    formikProps={formikProps} // Add this line
                  />
                  <Grid item container justify="center">
                    {formikProps.status && formikProps.status.isError && (
                      <Typography style={{ color: 'red' }}>{formikProps.status.message}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
};

export default AppForm;
