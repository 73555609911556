//app-header.tsx
import cc from "classcat";
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid  from '@material-ui/core/Grid';
import { useCustomizations } from "@personicom/customizations";

const AppHeader = () => {
  const classes = buildStyles();

  const {strings, links, flags, images, isIframe, blobUrl } = useCustomizations();
  
   //Don't display the header if we're inside an iframe
  if(isIframe || Boolean(flags.isIframe)) return null;

  const logo = blobUrl(images.logo);

  return (
    <Grid id="app-header" item xs={12} sm container className={classes.headerRoot}  >
      <Grid item xs container direction="row" spacing={2} >
        <Grid item xs container justify={flags.centerLogo ? "center" : undefined}>
          <a href={links.homeUrl} target="_blank" rel="noreferrer">
            <img className={cc({"img-responsive": flags.responsiveLogo, [classes.logoImage] : true})} src={logo} alt={strings.logoAlt}/>
          </a>
        </Grid>
        <Grid item>
          {strings.hasOwnProperty('headerInfoText') ? <Link href={links.homeUrl} className={classes.headerInfoText} target="_blank">{strings.headerInfoText}</Link > : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AppHeader;

const buildStyles   = makeStyles((theme : any) => ({
  headerRoot  : {
    padding: "20px 40px 16px 40px",
    backgroundColor: `${ theme.styles.header?.backgroundColor ??  theme.styles.page.backgroundColor}`,
    borderBottom  : theme.styles.header?.borderBottom ?? `${theme.styles.header?.borderBottomWidth ?? "5px"} solid ${theme.styles.page.borderColor}`, //`
    [theme.breakpoints.down('xs')]  : {
      alignItems        : "center",
      justifyContent   : "center",
    }
  },
  headerInfoText: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.styles.header?.color ??  theme.styles.page.color,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      textAlign: "center",
    },
  },
  logoImage   : {
    height: `${ theme.styles.header?.logo?.height ?? "70px"}`, //`
    maxHeight: `${ theme.styles.header?.logo?.maxHeight ?? "none"}`, //`
    marginLeft : 15,
    [theme.breakpoints.down('xs')]  : {
      height    : 55,
      marginLeft: 0,
    }
  }
}));