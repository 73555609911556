// index.tsx
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import CustomizationsProvider from "@personicom/customizations";
import { ErrorProvider } from "@personicom/customizations";


// Wrap App with CustomizationsProvider and ErrorProvider
ReactDOM.render(
  <React.StrictMode>
    <CustomizationsProvider>
      <ErrorProvider>
        <App />
      </ErrorProvider>
    </CustomizationsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

