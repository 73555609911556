//handlebars-helpers.ts
import Handlebars from 'handlebars';

// Register the toLowerCase helper
Handlebars.registerHelper('toLowerCase', function (value: any): string {
  if (!value) return '';
  return value.toString().toLowerCase();
});

// Register a helper for undefined variables
Handlebars.registerHelper("helperMissing", function (...args: any[]): string {
  const options = args[args.length - 1]; // Last argument is the options object
  const name = options.name; // The name of the missing variable
  return `{{${name}}}`; // Return the original expression
});


// Export the configured Handlebars instance
export default Handlebars;