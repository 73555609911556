//App.tsx
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CustomizationsContext, CustomizationHelmet, ErrorBoundary, ErrorCard, useError, LoadingIndicator } from "@personicom/customizations";
import AppHeader from "./app-header";
import AppBody from "./app-body";
import { Grid } from "@material-ui/core";

// Google Analytics tracking
const GA_TRACKER = "UA-102377011-8";
const GA_TRACKER_SECONDARY = "UA-157273197-1";  // Fallback in case not found in layout.json

const App: React.FC = () => {
  const { isInitialized } = useContext(CustomizationsContext);
  const [isReady, setReady] = useState(false);
  const { error, setError, clearError } = useError(); // useError hook now imported directly

  useEffect(() => {
    async function initialize() {
      try {
        setReady(true);
      } catch (ex: any) {
        console.log("Initialization Error", ex);
        setError({ title: "Initialization Error", detail: ex.message || ex.toString() });
      }
    }
    initialize();
  }, [setError]);


  if (error) {
    return (
      <Grid container direction="column" alignItems="center" style={{ marginTop: "10%" }}>
        <ErrorCard message={error.title} details={error.detail} />
      </Grid>
    );
  }

  if (!isInitialized || !isReady) {
    return <LoadingIndicator />;
  }


  return (
    <Router>
      <CustomizationHelmet gaTrackerId={GA_TRACKER} gaTrackerSecondaryId={GA_TRACKER_SECONDARY} />
      <ErrorBoundary>
        <AppHeader />
        <AppBody />
      </ErrorBoundary>
    </Router>
  );
}

export default App;
