//formik-image-field.tsx
import React, { useEffect, useMemo, useState } from "react";
import { isArray, isString } from "lodash";
import cc from "classcat";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, MenuItem, Select, Typography } from "@material-ui/core";
import { PhotoCamera, Close } from "@material-ui/icons"
import { AppFieldOption, FormikFieldProps } from "../app-types";
import { useCustomizations } from "@personicom/customizations";
import { GetPathFunc } from "@personicom/customizations/lib/esm/customization-types";
import { getFileUrl, hasHandlebars } from "features/app/app-helpers";

const buildStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.primary.main}`, //`
    borderRadius: 3,
  },
  errorContainer: {
    background: `${theme.palette.error.light}33`, //`
    border: `1px dashed ${theme.palette.error.light}`, //`
  },
  input: {
    display: "none",
  },
  imageContainer: {
    display: 'inline-block',
    background: "repeating-conic-gradient(#cccccc 0% 25%, #999999 0% 50%) 0 0 / 20px 20px"
  },
  image: {
    // marginBottom: theme.spacing(1),
  },
  imageLabel: {
    fontSize: 16,
    color: "#47505e",
  },
  actionButton: {
    marginTop: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: 15,
  },
  select: {
    marginTop: theme.spacing(1),
    "&.MuiInput-underline:before": {
      borderColor: `${(theme as any).styles.page.color} !important`,
    },
    "& .MuiSelect-root:hover:before": {
      borderBottomColor: (theme as any).styles.page.color,
    },
    "& .MuiSelect-root": {
      color: (theme as any).styles.page.color,
    },
    "& .MuiSelect-icon": {
      color: (theme as any).styles.page.color,
    },
    "& .MuiSelectLabel-root": {
      color: (theme as any).styles.page.color,
      fontSize: (theme as any).styles.page.fontSize || 15,
      fontWeight: (theme as any).styles.page.fontWeight || 'normal'
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
    }
  },
}));

// const getFileUrl = (value: string | File | undefined, blobUrl: GetPathFunc) => {
//   if(!value) return "";
//   else if(isString(value)){
//     if(value.startsWith("http://") || value.startsWith("https://")){
//       return value;
//     }
//     else{
//       return blobUrl(value);
//     }
//   }

//   return URL.createObjectURL(value);
// }

const createImageChoice = (option: string | AppFieldOption, index: number, blobUrl: GetPathFunc) => {
  if (isString(option)) {
    const url = option.indexOf("http") >= 0 ? option : blobUrl(option);
    return {
      id: index,
      values: url,
      label: `Image ${index + 1}`
    };
  }
  else {
    const url = option.values.indexOf("http") >= 0 ? option.values as string : blobUrl(option.values);
    return {
      ...option,
      values: url,
    };
  }
}

const FormikImageField: React.FC<FormikFieldProps> = ({ formikProps, fieldProps, disabled, options: injectedDefault, allInputs }) => {
  const classes = buildStyles();
  const { blobUrl } = useCustomizations();
  const isTemplated = useMemo(() => hasHandlebars(fieldProps.default), [fieldProps]);
  const [isTouched, setWasTouched] = useState(false);
  const [myDirty, setMyDirty] = useState<string[]>([]);

  const choices = useMemo(() => {
    //check for injected options, and use those if available.
    if (isArray(injectedDefault))
      return injectedDefault.map((opt, idx) => createImageChoice(opt, idx, blobUrl)); //opt.indexOf("http") >= 0 ? opt : blobUrl(opt));

    // otherwise, check for options on the fieldProp from the config, and use those
    else if (isArray(fieldProps.options)) {
      return fieldProps.options.map((opt, idx) => createImageChoice(opt, idx, blobUrl));
    }

    return null;
  }, [injectedDefault, fieldProps]);

  //determine the default selected value
  const defaultSelected = useMemo(() => {
    //precedence / rules (per 10/13/21 discussion w/ Frank): 
    // 1. if injectedDefault is array, choose the first item.
    // 2. else if injectedDefault has a value, use that
    // 3. else if fieldProps has a default:
    //   2b. If there are fieldProps.options and fieldProps.default is an int, default to fieldProps.options[fieldProps.default] (e.g. fieldProps.default must be an ordinal)
    //   2c. else if there are fieldProps.options and fieldProps.default is not an int, find fieldProps.options where value === fieldProps.default (e.g. find the fieldProps.option that matches the fieldProps.defaul)
    //   2d. else if no fieldProps.options and fieldProps.default has a value, use fieldProps.default
    // 3. if there are fieldProps.options and no fieldProps.default, use fieldProps.options[0]
    // 4. no default value.
    // convert the resulting value to a file Url since the Select option values will be urls to the image
    let val = "";
    if (isArray(injectedDefault)) {
      val = injectedDefault[0];
    }
    else if (injectedDefault) {
      val = injectedDefault;
    }
    else if (fieldProps.default) {
      const defaultOrdinal = parseInt(fieldProps.default.toString());
      const isStringOptions = fieldProps.options && isString(fieldProps.options[0]);
      if (!fieldProps.options) {
        val = fieldProps.default.toString();
      }
      else if (!isNaN(defaultOrdinal)) {
        const ord = (defaultOrdinal >= 0 && defaultOrdinal < fieldProps.options.length) ? defaultOrdinal : 0;
        const optValue = fieldProps.options[ord];
        val = isStringOptions ? optValue : (optValue as AppFieldOption).values;
      }
      else {
        if (isStringOptions) {
          val = (fieldProps.options as string[]).find(s => s === fieldProps.default?.toString()) ?? "";
        }
        else {
          const optValue = (fieldProps.options as AppFieldOption[]).find(o => o.values === fieldProps.default?.toString());
          val = optValue?.values ?? "";
        }
      }
    }
    else if (fieldProps.options) {    //fieldProps.options, but no fieldProps.default
      val = (fieldProps.options[0] as AppFieldOption)?.values ?? fieldProps.options[0];
    }

    if (val) return getFileUrl(val, blobUrl);
    else return val;
  }, [fieldProps.default, injectedDefault]);

  const [selected, setSelected] = useState<string>(defaultSelected);
  const [fileUrl, setFileUrl] = useState<string>(getFileUrl(selected, blobUrl));

  useEffect(() => {
    if (!isTouched && (formikProps.isSubmitting || formikProps.touched[fieldProps.fieldId])) {
      setWasTouched(true);
    }
  }, [isTouched, fieldProps.fieldId, formikProps.isSubmitting, formikProps.touched]);

  const errorMessage = useMemo(() => { return isTouched ? formikProps.errors[fieldProps.fieldId] || "" : ""; }, [isTouched, formikProps.errors, fieldProps.fieldId]);

  useEffect(() => {
    const formValue = formikProps.values[fieldProps.fieldId];
    if (isTemplated && formValue && formValue !== fileUrl) {
      setFileUrl(formValue);
      //console.log("updated image url: ", formValue);
    }
  }, [formikProps, fieldProps, fileUrl, isTemplated]);

  const imgStyle = useMemo(() => fieldProps.style || {}, [fieldProps]);
  // let imageIndex = 0;

  //-- Handles the case where there's a default value, need to assign it to the formik form
  useEffect(() => {
    if (!isTemplated && defaultSelected && formikProps && formikProps.values[fieldProps.fieldId] !== defaultSelected) {

      //HACK: FTO: when the user cleared the image, this effect was firing during submitting and restoring the default. For some reason
      //touched was being cleared.
      //So hack is to hold my own dirty array, to be really certain the user changed something on the form, and not to restore the default.
      if (!myDirty.includes(fieldProps.fieldId)) {
        formikProps.setFieldValue(fieldProps.fieldId, defaultSelected);
        console.log(`Default Override [${fieldProps.fieldId}]: ${defaultSelected} `);
      }
    }
  }, [isTemplated, isTouched, defaultSelected, formikProps])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (!files) return;

    const fileObj = files[0];
    const fileUrl = getFileUrl(fileObj, blobUrl); //fileObj ? URL.createObjectURL(fileObj) : null;

    formikProps.setFieldValue(fieldProps.fieldId, fileObj);
    setFileUrl(fileUrl ?? "");
  }

  const onClear = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    formikProps.setFieldValue(fieldProps.fieldId, "");
    formikProps.setFieldTouched(fieldProps.fieldId, true);
    setMyDirty(prevDirty => [...prevDirty, fieldProps.fieldId]);  //hack
    setFileUrl("");
    setSelected("");
    console.log("onClear:" + fieldProps.fieldId);
  }

  const onSelectedImageChange = (e: any) => {
    const newVal = e.target.value;
    setSelected(newVal);

    formikProps.setFieldValue(fieldProps.fieldId, newVal);
    setFileUrl(newVal ?? "");
    setMyDirty(prevDirty => [...prevDirty, fieldProps.fieldId]);  //hack
  }

  return (
    <Grid container direction="column" className={cc({ [classes.container]: true, [classes.errorContainer]: !!errorMessage })} justify="center" alignItems="center">
      <input accept="image/*" className={classes.input} id={fieldProps.fieldId} type="file" onChange={onChange} disabled={fieldProps.isReadOnly || disabled} />
      <label htmlFor={fieldProps.fieldId}>
        <Grid container direction="column" justify="center" alignItems="center">
          {!fileUrl && <Typography color="primary" className={classes.imageLabel}>{fieldProps.fieldLabel}{fieldProps.isRequired ? "*" : ""}</Typography>}
          {fileUrl &&
            <Grid item className={classes.imageContainer}>
              <img src={fileUrl} className={classes.image} alt={fieldProps.fieldLabel} style={imgStyle} />
            </Grid>
          }
          <Grid container justify="center">
            {!fieldProps.isReadOnly &&
              <IconButton component="span" disabled={disabled} title={`Click to choose the ${fieldProps.fieldLabel}`} color="primary" aria-label="upload picture" className={classes.actionButton}>
                <PhotoCamera />
              </IconButton>
            }
            {fileUrl && !fieldProps.isRequired &&
              <IconButton component="span" disabled={disabled} title="Remove" color="primary" aria-label="remove picture" className={classes.actionButton} onClick={onClear}>
                <Close />
              </IconButton>
            }
          </Grid>
          {choices &&
            <Select onChange={onSelectedImageChange} value={selected} margin="dense" displayEmpty className={classes.select}>
              <MenuItem disabled value="">Please Select</MenuItem>
              {choices.map(c => {
                return <MenuItem key={c.id} value={c.values}>{c.label}</MenuItem>
              })}
            </Select>
          }
          <Typography className={classes.errorText}>{errorMessage}</Typography>
        </Grid>
      </label>
    </Grid>
  )
}

export default FormikImageField;