//src/features/app/app-form-components.tsx
import React from 'react';
import { Grid, Button, Typography, Link } from "@material-ui/core";
import { buildStyles } from "./app-form-styles";
import { AppActionProps, AppFieldProps } from "app-types";
import { FormikField, ActionButton } from "components";
import { createGridItemStyle } from "./app-form-styles";
import { LoadingIndicator } from "@personicom/customizations";
import { FormikProps } from 'formik';


interface FormActionsContainerProps {
  actions: AppActionProps[];
  onSubmitAction: (action: AppActionProps, formikProps: FormikProps<Record<string, string>>) => () => Promise<any>;
  isWorking: boolean;
  isValid: boolean;
  formikProps: FormikProps<Record<string, string>>; // Add this line if needed
}

const useStyles = buildStyles;

// Renders a grid for all form fields
export const FormGridContainer: React.FC<{
  inputs: AppFieldProps[];
  formikProps: FormikProps<Record<string, any>>;
  injectedValues: Record<string, any> | null;
  disabled: boolean;
  unused: Record<string, any>;
}> = ({ inputs, formikProps, injectedValues, disabled, unused }) => {
  
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      {inputs.map((inp: AppFieldProps) => (
        <div key={inp.fieldId} style={createGridItemStyle(inp.grid)}>
          <FormikField
            fieldProps={inp}
            formikProps={formikProps}
            disabled={disabled}
            options={(injectedValues ?? {})[inp.fieldId]}
            allInputs={inputs}
            unusedValues={unused}
          />
        </div>
      ))}
    </div>
  );
};

// Renders a container with form action buttons
export const FormActionsContainer: React.FC<FormActionsContainerProps> = ({
  actions,
  onSubmitAction,
  isWorking,
  isValid,
  formikProps,
}) => {
  
  const classes = useStyles();

  return (
    <div className={classes.actionsContainer}>
      {actions.map((act: AppActionProps) => (
        <div key={act.id} style={createGridItemStyle(act.grid)}>
          <ActionButton
            actionProps={act}
            onClick={onSubmitAction(act, formikProps)} // Use formikProps here
            isWorking={isWorking}
            isValid={isValid}
          />
        </div>
      ))}
    </div>
  );
};

export const LoadingWrapper: React.FC<{ message: string }> = ({ message }) => {
  const classes = useStyles();
  return (
    <Grid item container className={classes.loading}>
      <LoadingIndicator message={message} />
    </Grid>
  );
};


// Component to display the result of the form submission
export const RenderResult: React.FC<{ url: string | null; onReset: () => void }> = ({ url, onReset }) => {
  const classes = useStyles();
  if (!url) return null;

  return (
    <Grid item container direction="column" justifyContent="center" alignItems="center" className={classes.renderedGrid}>
      <Typography color="secondary">
        Render Complete: <Link target="_blank" href={url}>Click Here to View your Video</Link>
      </Typography>
      <Button onClick={onReset} size="large" color="primary" variant="outlined" className={classes.actionButton}>
        Start Over
      </Button>
    </Grid>
  );
};