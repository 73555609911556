//general-hooks.ts
import { useEffect } from 'react';
import { useRef } from 'react';

export type CallbackFunc = () => void;

//A hook for calling a callback method periodically until delay is set to null
export function useInterval(callback: CallbackFunc, delay: number | null) {
  const savedCallback = useRef<CallbackFunc>();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if(savedCallback.current) savedCallback.current();
    }

    if (delay !== null && savedCallback.current) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}