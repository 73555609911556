//formik-image-select-field.tsx
import React, { useState, useEffect, FC } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Typography, TextField } from "@material-ui/core";
import { AppFieldOption, FormikFieldProps } from "../app-types";
import ClearIcon from '@material-ui/icons/Clear';
import { useCustomizations } from "@personicom/customizations";
declare var Handlebars: any;


interface ImageOption {
  fileName: string;
}

const buildStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.primary.main}`, //`
    borderRadius: 3,
  },
 
  errorContainer: {
    background: `${theme.palette.error.light}33`, //`
    border: `1px dashed ${theme.palette.error.light}`, //`
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: 15,
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,    
  },
  label: {
    color: (theme as any).styles.page.color,
    fontSize: 15,
  },
  helperText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(0.5),
  },
  select: {
    marginBottom: theme.spacing(1),
    "&.MuiInput-underline:before": {
      borderColor: `${(theme as any).styles.page.color} !important`,
    },
    "& .MuiSelect-root:hover:before": {
      borderBottomColor: (theme as any).styles.page.color,
    },
    "& .MuiSelect-root": {
      color: (theme as any).styles.page.color,
    },
    "& .MuiSelect-icon": {
      color: (theme as any).styles.page.color,
    },
    "& .MuiSelectLabel-root": {
      color: (theme as any).styles.page.color,
      fontSize: (theme as any).styles.page.fontSize || 15,
      fontWeight: (theme as any).styles.page.fontWeight || 'normal'
    }
  },
  image: {
    width: '200px',
    height: '200px',
  },
  imageContainer: {
    textAlign: 'center',
    background: "repeating-conic-gradient(#cccccc 0% 25%, #999999 0% 50%) 0 0 / 20px 20px",
    padding: theme.spacing(1),
  },
  imageName: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
}));

const mapEnvironment = (env: string): string => {
  const lowerCaseEnv = env.toLowerCase(); // Convert to lowercase for case-insensitive comparison

  switch (lowerCaseEnv) {
    case 'development':
      return 'dev';
    case 'qa':
      return 'qa';
    case 'production':
      return 'prod';
    default:
      return 'prod'; // Default to 'prod' if unsure
  }
};


const FormikImageSelectField: FC<FormikFieldProps> = ({ formikProps, fieldProps, disabled, allInputs }) => {
  const [images, setImages] = useState<ImageOption[]>([]);
  const classes = buildStyles();
  const customizations = useCustomizations(); 

   // Transform the environment property
   const transformedCustomizations = {
    ...customizations,
    envSuffix: mapEnvironment(customizations.assetEnvironment),
  };

   // Extract baseUrl and thumbSize from metadata
   const baseUrlTemplate = fieldProps.metadata?.baseUrl || "{{defaultBaseUrl}}";
   const baseUrl = Handlebars.compile(baseUrlTemplate)(transformedCustomizations);
   const thumbSize = fieldProps.metadata?.thumbSize || 200;

  // Function to get the base file name without extension
  const getBaseFileName = (fileName: string): string => {
    const splitFileName = fileName.split('.');
    return splitFileName.slice(0, -1).join('.');
  };

  // Function to get the full URL of the image
  const getImageUrl = (fileName: string): string => {
    const baseFileName = getBaseFileName(fileName); 
    const extension = fileName.split('.').pop();
    return `${baseUrl}${baseFileName.toLowerCase()}.${extension}`;
  };

  const getThumbnailFileName = (fileName: string): string => {
    const baseFileName = getBaseFileName(fileName);
    const extension = fileName.split('.').pop();
    return `${baseUrl}${baseFileName.toLowerCase()}_${thumbSize}x${thumbSize}.${extension}`;
  };
 

  // Function to fetch images
  const fetchImages = async () => {
    try {
      console.log(`fetchImages`)
      const response = await fetch(`${baseUrl}_assets.json`);
      const data = await response.json();      
      setImages(data);
    } catch (error) {
      console.error('Error fetching images:', error);
      // Handle error as needed
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);


  return (
    <Grid container spacing={2} className={classes.container} alignItems="center" justify="space-between">
       <Grid item xs={12} md={4}> 
        <Autocomplete
            options={images}
            getOptionLabel={(option: ImageOption) => getBaseFileName(option.fileName)}
          renderOption={(option: ImageOption) => (
            <Grid container alignItems="center">
              <Grid item>
                <img
                  src={getThumbnailFileName(option.fileName)}
                  alt={option.fileName}
                  style={{ width: 30, height: 30, marginRight: 8 }}
                />
              </Grid>
              <Grid item>
                {getBaseFileName(option.fileName)}
              </Grid>
            </Grid>
          )}
          onChange={(event, newValue: ImageOption | null) => {
            formikProps.setFieldValue(fieldProps.fieldId, newValue ? newValue.fileName : '');
          }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Logos..."
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            disabled={disabled}
            fullWidth
          />
          <Typography variant="caption" className={classes.label}>
            Search our brand database for your client's logo.
          </Typography>

      </Grid>
      <Grid item xs={12} md={8} className={classes.imageContainer}>
        {formikProps.values[fieldProps.fieldId] && (
          <>
            <img
              src={getImageUrl(formikProps.values[fieldProps.fieldId])}
              alt={getBaseFileName(formikProps.values[fieldProps.fieldId])}
              className={classes.image}
            />
            <Typography variant="subtitle1" className={classes.imageName}>
              {getBaseFileName(formikProps.values[fieldProps.fieldId])}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default FormikImageSelectField;
