//app-helpers.ts
import { AppActionProps, AppFieldOption, AppFieldProps, AppFormProps, ConditionRecord, CombinedCondition } from "app-types";
import { filter, isArray, isNull, isString, isUndefined, keys } from "lodash";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import { GetPathFunc } from '@personicom/customizations/lib/esm/customization-types';

const maxWarning = (len: number) => `Please enter no more than ${len} characters`;
const reqWarning = () => "This field is required";
const CAPTCHA_ID = "captcha";

//Default action button for when there isn't one defined in app.json.
export const DEFAULT_ACTION : AppActionProps = {
  id: "submit",
  label: "Create Video",
  size: "medium",
};

const caseInsensitive = (value: any) => {
  if(!value) return value;
  
  if(isArray(value)) return value.map( v=> v.toString().toLowerCase()); //Don't box it into a string if it's an array.

  if(isString(value)) return value.toLowerCase();  
  return value.toString().toLowerCase();
}

//----
// Compares a value against a condition for an AppFieldProp
const conditionalCompare = (value: string, condition: ConditionRecord) => {
  if(!value) return false;
  const compareTo = caseInsensitive(condition.value);
  const myValue = caseInsensitive(value);

  switch(condition.operator){
    case "in":
      const inValue = isArray(compareTo) ? compareTo.map(v => caseInsensitive(v)) : [caseInsensitive(compareTo)];
      return inValue.indexOf(myValue) >= 0;

    case "notin":
      const notInValue = isArray(compareTo) ? compareTo.map(v => caseInsensitive(v)) : [caseInsensitive(compareTo)];
      return notInValue.indexOf(myValue) == -1;

    case "!=":
      const notEqualValue = isArray(compareTo) ? caseInsensitive(compareTo[0]) : caseInsensitive(compareTo);
      return myValue !== notEqualValue;

    case "=":
    default:
      //TODO: throw an exception if the schema defines an = operator to an array? For now, just get the first value.
      const equalValue = isArray(compareTo) ? caseInsensitive(compareTo[0]) : caseInsensitive(compareTo);
      return myValue === equalValue;

  }
}

const createConditionalRequiredField = (field: Yup.StringSchema, input: AppFieldProps) => {
  if (!input.condition || !input.isRequired) return field;

  if ('fieldId' in input.condition) {
    // Existing single condition logic
    const singleCondition = input.condition as ConditionRecord;
    return field.when(singleCondition.fieldId, {
      is: (val: string) => conditionalCompare(val, singleCondition),
      then: field.required(),
      otherwise: field.optional(),
    });
  }

  // Multiple conditions
  const combinedCondition = input.condition as CombinedCondition;
  const fieldIds = combinedCondition.conditions.map(c => c.fieldId);
  
  return field.when(fieldIds, {
    is: (...vals: string[]) => {
      const results = combinedCondition.conditions.map((condition, index) => 
        conditionalCompare(caseInsensitive(vals[index]), condition)
      );
      return combinedCondition.combineOperator === "AND"
        ? results.every(r => r)
        : results.some(r => r);
    },
    then: field.required(),
    otherwise: field.optional(),
  });
};

export const createSchema = (inputs: Record<string, any>[]) => {


  const schema = inputs.reduce((output, input) => {
    let field : any = null;

    switch(input.fieldType){
      case "string":
      case "email":
      case "phone":
      case "hidden":
      case "url":
        field = Yup.string();
        if(input.condition && input.isRequired){
          field = createConditionalRequiredField(field, input as AppFieldProps);
        }
        else if(input.isRequired === true) field = field.required(input.requiredWarning ?? reqWarning());

        if(input.max) field = field.max(input.max, maxWarning(input.max));        
        break;
      case "json":
        field = Yup.string().isValidJSONString("Invalid JSON");
        if(input.condition && input.isRequired){
          field = createConditionalRequiredField(field, input as AppFieldProps);
        }
        else if(input.isRequired === true) field = field.required(input.requiredWarning ?? reqWarning());
        
        break;
      case "image":
      case "file":
        field = Yup.string();
        if(input.condition && input.isRequired){
          field = createConditionalRequiredField(field, input as AppFieldProps);
        }
        else if(input.isRequired === true) field = field.required(input.requiredWarning ?? reqWarning());
        break;

      case "select":
        field = Yup.string();
        if(input.condition && input.isRequired){
          field = createConditionalRequiredField(field, input as AppFieldProps);
        }
        else if(input.isRequired === true) field = field.required(input.requiredWarning ?? reqWarning());
        break;

      case "captcha":
        field = Yup.string().required();
    };

    if(field){
      if(input.fieldType === "captcha") output[CAPTCHA_ID] = field;
      else output[input.fieldId] = field;
    }

    return output;

  }, {} as ObjectShape);

  return Yup.object().shape(schema);
}

declare module 'yup' {
  interface StringSchema {
    isValidJSONString(errorMessage?: string): this;
  }
}

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'isValidJSONString',
  function (errorMessage: string = 'This field must be valid JSON') {
    return this.test(`test-card-type`, errorMessage, function (value) {
      const { path, createError } = this;
      
      if ( value == null ) return true;

      return (
        isValidJSONString(value!) ||
        createError({ path, message: errorMessage })
      );
    })
  },
);

function isValidJSONString(str:string) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const getDefaultValue = (defaults: Record<string, any>, input: Record<string, any>, getBlob?: GetPathFunc) => {
  const defaultValue = defaults[input.fieldId];
  if(defaultValue && isArray(defaultValue)){
    return defaultValue[0];
  }

  const value = defaultValue ?? input.default  ?? "";

  //If this is an image that isn't a full url, then need to convert it to a blob url
  if(value && input.fieldType === "image" && isString(value) && value.indexOf("http") < 0 && getBlob){
    return getBlob(value);
  }

  return value;
}

export const createInitialValues = (inputs: Record<string, any>[], defaults : undefined | Record<string, any> = undefined, getBlob: GetPathFunc) => {
  const myDefaults = defaults ?? {};
  const initValues = inputs.reduce((output, input) => {
    
    //determine if this input is applicable given the current values
    const tempValues = {...myDefaults, ...output};
    const isApplicable = isConditionMet(input as AppFieldProps, tempValues);
    if(!isApplicable) return output;

    switch(input.fieldType){
      case "hidden":
      case "string":
      case "email":
      case "phone":
      case "url":
        output[input.fieldId] = getDefaultValue(myDefaults, input);     
        break;
      
      case "image":
        output[input.fieldId] = getDefaultValue(myDefaults, input, getBlob);     
        break;

      case "select":
        output[input.fieldId] = getDefaultValue(myDefaults, input);     
        break;

      case "captcha":
        output[CAPTCHA_ID] = null;
        break;
    };

    return output;
  }, {} as Record<string, any>);

  console.log("initial values:", initValues);
  return initValues;
}

export const swapSelectValues = (inputs: AppFieldProps[], values: Record<string, any> ) => {
  const selects = inputs.filter(i => i.fieldType === "select");
  if(!selects || selects.length === 0) return values;
  
  var selectedValues = {};
  selects.forEach(function(sel, index, selections){
    const isApplicable = isConditionMet(sel, values);  //only applies if the field meets it's condition (or doesn't have a condition)
    if(isApplicable){
      const val = values[sel.fieldId];
      if(val && sel.options && !isString(sel.options[0])){  //Only applies if the input field has options, and the options are not strings
        const choices = sel.options as AppFieldOption[];
        const opt = choices.find(o => o.id === val);
        if(opt){
          selectedValues = {...selectedValues, ...opt.values};
        }
      }
    }
  });  
  return {
    ...values,
    ...selectedValues,
  };
}

interface PropertyGroup {
  propertyName: string;
  inputs: AppFieldProps[];
};


//This checks ot see if there is a value... need this so that false is recognized as a value, as other
//methods will treat false as a non-value
const hasValue = (value: any) => {
  const iNull = isNull(value);
  const iUnd = isUndefined(value);
  const isEmpty = value === "";
  return !iNull && !iUnd && !isEmpty;
}

//===
// Looks for and swaps out the keys of the object with propertyNames from the AppFieldProps.
export const swapPropertyNames = (inputs: AppFieldProps[], prepared: Record<string, any>) => {
  //Get an array of the fields with a propertyName field, turn it into groups with the propertyName and an array of the inputs themselves.
  const propNameGroups = inputs
    .filter(inp => !!inp.propertyName)
    .reduce((groups: PropertyGroup[], inp) => {
      const existing = groups.find(g => g.propertyName === inp.propertyName);
      if(existing){
        existing.inputs.push(inp);
      }
      else{
        groups.push({propertyName: inp.propertyName as string, inputs: [inp]});
      }
      return groups;
    }, []);

  //If we don't have any propNameGroups, just return the original object
  if(propNameGroups.length === 0) return prepared;

  //Construct a new object by replacing fieldIds with propertyNames where applicable.
  const output = Object.keys(prepared)
    .reduce((obj: Record<string, any>, key) => {
      //Get the field for this object property key, and see if there's a propertyName on the field
      const field = inputs.find(inp => inp.fieldId === key);
      //If this field has a propertyName then we need to swap the key out for the propertyName
      if(field?.propertyName){
        //Make sure we haven't already assigned this propertyName (multiple fields can have the same propertyName)
        if(hasValue(obj[field.propertyName])) return obj; 

        //find the group for this propertyName
        const group = propNameGroups.find(grp => grp.propertyName === field.propertyName);
        if(group){
          //Need to replace the key with the propertyName
          let value = prepared[key];
          if(group.inputs.length > 0){

            //There are multiple inputs with the same propertyName, so need to choose the first non-empty value
            const firstInputWithValue = group.inputs.find(inp => hasValue(prepared[inp.fieldId]));
            if(firstInputWithValue){
              value = prepared[firstInputWithValue.fieldId];
            }
          }

          obj[group.propertyName] = value;
          return obj;
        }
      }

      //no propertyName replacement, or property has already been replaced, so just use the key
      obj[key] = prepared[key];

      return obj;
    }, {});

    return output;
}

export const validateForm = (appForm: AppFormProps, initialValues: Record<string, any>) => {
  //Make sure all the hidden, required fields have default values
  const hiddenRequired = appForm.inputs.filter(f => f.hideField && f.isRequired);
  if(hiddenRequired && hiddenRequired.length > 0){
    const invalid = hiddenRequired.find(field => !initialValues[field.fieldId]);
    if(invalid){
      return "The form is invalid. There is at least one hidden, required field without a provided value.";
    }
  }

  return null;
}

///---
/// Alternate version of the condition evaluator that takes the values directly, rather than from the
/// formik properties.
export const isConditionMet = (field: AppFieldProps, values: Record<string, any>) => {
  //TODO: handle option level values. When someone selects an option, we should probably just set the formikProps.values based on the additional option values.
  if(!field.condition) return true;

  // Handle single condition (backward compatibility)
  if ('fieldId' in field.condition) {
    const singleCondition = field.condition as ConditionRecord;
    const currentValue = caseInsensitive(values[singleCondition.fieldId]);
    if (!currentValue) return false;
    return conditionalCompare(currentValue, singleCondition);
  }

  // Handle multiple conditions
  const combinedCondition = field.condition as CombinedCondition;
  const results = combinedCondition.conditions.map(condition => {
    const currentValue = caseInsensitive(values[condition.fieldId]);
    if (!currentValue) return false;
    return conditionalCompare(currentValue, condition);
  });

  return combinedCondition.combineOperator === "AND"
    ? results.every(result => result)
    : results.some(result => result);
}

//TODO: Move this updated version to Customizations
export function getSearchParam(key: string, keepCase = false) : string | null {
  let searchVal = window.location.search.slice(1);
  if(!keepCase) searchVal = searchVal.toLowerCase();
  const search = new URLSearchParams(searchVal);
  return search.get(key);
}


//---
// Converts the query string into a key/value object.
//TODO: FRANK - here is where the query string values are pulled from the Query String
export const getQueryStringValues = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  let output : Record<string, any> = {};
  searchParams.forEach((value, key) => {
    const existing = output[key];
    if(existing){
      //multiple values, so create / add to an array
      if(isArray(existing)){
        output[key] = [...existing, value];
      }
      output[key] = [existing, value];
    }
    else{
      output[key] = value;
    }
  });

  return output;
}

//Takes all the various sources of form values and compiles them into the final
//master list of all values for the form.
export const compileValues = (
  allInputs: AppFieldProps[], 
  formValues: Record<string, string>, 
  client: string, 
  subdomain: string, 
  unusedValues: Record<string, any> | undefined,
  forSubmit: boolean) => {
  //swap out any select values for the value in the app.json, if necessary
  let prepared = swapSelectValues(allInputs, formValues);
  //handle the propertyName which can override the fieldId as the prop key for the submitted structure
  //NOTE: only swap names when its for submit so it doesn't mess up the validation for fields with different property names
  if(forSubmit) {

    //Handle any JSON fields, deserialize it to an object.
    allInputs.filter(inp => inp.fieldType=="json").forEach( function (fieldProp)
      {
        prepared[fieldProp.fieldId] = JSON.parse(prepared[fieldProp.fieldId]);
      }
    );

    prepared = swapPropertyNames(allInputs, prepared)
  }

  //Add the unused vales to the final values to send
  let newValues:Record<string,any> = {
    client: client,
    rootDomain: client,
    subdomain: subdomain,
    ...prepared,
    ...unusedValues,
  };

  return newValues;
}

export const hasHandlebars = (val: any) => {
  return val && isString(val) && val.includes("{{");
}

export const getFileUrl = (value: string | File | undefined, blobUrl: GetPathFunc) => {
  if(!value) return "";
  else if(isString(value)){
    if(value.startsWith("http://") || value.startsWith("https://")){
      return value;
    }
    else{
      return blobUrl(value);
    }
  }

  return URL.createObjectURL(value);
}


// Gets the body fields that are blobs
export const getBlobFields = (body: Record<string, any>): string[] | false => {
  if (!body) return false;
  const imgFields = filter(keys(body), key => body[key] instanceof Blob);
  return imgFields;
};

//--
// Will prepare the body to be sent to the server
// This includes checking for images and using FormData if necessary.
// Otherwise, returns a stringified version of the body (if it's not already a string)
// Will prepare the body to be sent to the server
// This includes checking for images and using FormData if necessary.
// Otherwise, returns a stringified version of the body (if it's not already a string)
export const prepareBody = (body: Record<string, any> | string | null | undefined): FormData | Record<string, any> | string | null | undefined => {
  if (!body) {
    console.log("Body is empty or undefined.");
    return body;
  }

  if (typeof body === "string") {
    console.log("Body is a string:", body);
    return body;
  }

  const blobFields = getBlobFields(body);
  if (blobFields && blobFields.length > 0) {
    // This body has one or more image files, so need to use FormData
    let formData = new FormData();

    // Enumerate the image fields, append them to the FormData, and remove them from the body object
    blobFields.forEach(id => {
      formData.append(id, body[id] || new Blob());
      delete body[id];
    });

    // Add the remainder of the body as a payload
    formData.append("payload", JSON.stringify(body));
    
    // Log FormData contents
    console.log("FormData contents:");
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    return formData;
  } else {
    // Just return the object directly, do not stringify it
    console.log("Body is a JSON object:", body);
    return body;
  }
};
