// app-form-styles.tsx
import { makeStyles } from "@material-ui/core/styles";
import { CSSProperties } from "react";

export const buildStyles = makeStyles((theme: any) => ({
  title: { marginBottom: theme.spacing(2) },
  form: { width: "100%" },
  gridContainer: {
    display: "grid",
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(0.5),
  },
  actionsContainer: {
    display: "grid",
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  renderedGrid: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  actionButton: { marginTop: theme.spacing(2), ...theme.styles.actionButton },
  loading: { marginBottom: theme.spacing(2), marginTop: "-10%" },
  validationError: { margin: theme.spacing(2), textAlign: "center" },
  errorContainer: { "& .MuiGrid-container": { width: "100%" } }
}));

export const createGridItemStyle = (gridConfig: any) => {
  if (!gridConfig) return {};
  const area = gridConfig.area.indexOf("/") === 0 ? `${gridConfig.area} / 1 / 1 / 1` : gridConfig.area;

  const style = {
    display: "grid",
    gridArea: area,
  } as CSSProperties;

  if (gridConfig.align) style.alignContent = gridConfig.align;
  if (gridConfig.justify) style.justifyContent = gridConfig.justify;

  return style;
};
